











import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AnswerTextList extends Vue {
  @Prop({ required: false, default: null, type: String })
  private list: string;
}
